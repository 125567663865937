import React from 'react';
import { Outlet } from 'react-router-dom';

import FooterTwo from '../footer/footer2';
import NavbarV2 from '../navbarv2';

const DefaultLayout = ({ children }) => {
  return (
    <div>
      <header>
      <NavbarV2/>
      </header>
      <main>
        { children ? children :<Outlet /> }
      </main>
      <footer>
        <FooterTwo />
      </footer>
    </div>
  );
};

export default DefaultLayout;
