import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';

const PreCourseCalendar = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [seatAvailability, setSeatAvailability] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState({ '1': 0, '2': 0 });

  const ITEMS_PER_SLIDE = {
    mobile: 2,
    tablet: 4,
    desktop: 6
  };

  const dummyCategories = [
    { _id: '1', name: '' }
  ];

  const dummyCourses = [
    { _id: '1', category: '1', courseName: 'B.Tech Marine Engineering', subTitle: '' },
    { _id: '2', category: '1', courseName: 'B.Sc Nautical Science', subTitle: '' },
    { _id: '3', category: '1', courseName: 'DNS (IMU) Programme', subTitle: '' },
    { _id: '4', category: '1', courseName: 'Electro Technical Officer', subTitle: '' },
    { _id: '5', category: '1', courseName: 'GP Ratings (Deck & Engine)', subTitle: '' },
    { _id: '6', category: '1', courseName: 'CCMC Ratings', subTitle: '' }
  ];

  useEffect(() => {
    setCategories(dummyCategories);
    setCourses(dummyCourses);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      categories.forEach(category => {
        const categoryItems = courses.filter(course => course.category === category._id);
        const totalSlides = Math.ceil(categoryItems.length / ITEMS_PER_SLIDE.desktop);
        const currentSlideIndex = currentSlide[category._id];

        if (currentSlideIndex < totalSlides - 1) {
          handleNextSlide(category._id);
        } else {
          setCurrentSlide(prev => ({
            ...prev,
            [category._id]: 0
          }));
        }
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, courses, categories]);

  const generateDummyAvailability = () => {
    const availability = {};
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
      if (Math.random() > 0.7) {
        availability[day] = Math.floor(Math.random() * 8) + 1;
      }
    }
    return availability;
  };

  const handleCourseClick = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
    setLoading(true);
    setTimeout(() => {
      setSeatAvailability(generateDummyAvailability());
      setLoading(false);
    }, 500);
  };

  const handlePrevSlide = (categoryId) => {
    setCurrentSlide(prev => ({
      ...prev,
      [categoryId]: Math.max(0, prev[categoryId] - 1)
    }));
  };

  const handleNextSlide = (categoryId) => {
    const categoryItems = courses.filter(course => course.category === categoryId);
    const maxSlides = Math.ceil(categoryItems.length / ITEMS_PER_SLIDE.desktop) - 1;
    setCurrentSlide(prev => ({
      ...prev,
      [categoryId]: Math.min(maxSlides, prev[categoryId] + 1)
    }));
  };

  const renderCourseList = () => {
    return categories.map((category) => {
      const categoryItems = courses.filter(course => course.category === category._id);
      const totalSlides = Math.ceil(categoryItems.length / ITEMS_PER_SLIDE.desktop);
      const currentItems = categoryItems.slice(
        currentSlide[category._id] * ITEMS_PER_SLIDE.desktop,
        (currentSlide[category._id] + 1) * ITEMS_PER_SLIDE.desktop
      );

      const showPrevButton = currentSlide[category._id] > 0;
      const showNextButton = currentSlide[category._id] < totalSlides - 1;
      const showIndicators = totalSlides > 1;

      return (
        <div key={category._id} className="bg-[#EFF7FF] py-8 md:py-16">
          {category?.name && (
            <h2 className="mb-6 md:mb-10 text-center text-2xl md:text-4xl font-bold">
              <span className="bg-gradient-to-r from-[#1079B5] to-[#C11010] bg-clip-text text-transparent">
                {category.name}
              </span>
            </h2>
          )}

          <div className="container mx-auto px-4 relative">
            {showPrevButton && (
              <button 
                onClick={() => handlePrevSlide(category._id)}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 md:-translate-x-12 w-8 md:w-10 h-8 md:h-10 rounded-full bg-white shadow-lg flex items-center justify-center z-10"
              >
                <ChevronLeft className="h-4 md:h-6 w-4 md:w-6 text-gray-600" />
              </button>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-auto max-w-6xl">
              {currentItems.map((course) => (
                <div
                  key={course._id}
                  onClick={() => handleCourseClick(course)}
                  className="p-4 md:p-8 py-8 md:py-16 shadow-sm cursor-pointer hover:shadow-md transition-shadow bg-white"
                >
                  <h3 className="text-lg md:text-2xl font-bold mb-3 text-center">{course.courseName}</h3>
                  <p className="text-sm md:text-base text-gray-600 text-center">{course.subTitle}</p>
                </div>
              ))}
            </div>

            {showNextButton && (
              <button 
                onClick={() => handleNextSlide(category._id)}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 md:translate-x-12 w-8 md:w-10 h-8 md:h-10 rounded-full bg-white shadow-lg flex items-center justify-center z-10"
              >
                <ChevronRight className="h-4 md:h-6 w-4 md:w-6 text-gray-600" />
              </button>
            )}

            {showIndicators && (
              <div className="flex justify-center mt-6 md:mt-8 gap-2">
                {[...Array(totalSlides)].map((_, index) => (
                  <div
                    key={index}
                    className={`h-1.5 md:h-2 w-1.5 md:w-2 rounded-full ${
                      index === currentSlide[category._id] ? 'bg-blue-600' : 'bg-gray-300'
                    }`}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const renderCalendar = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDay = getFirstDayOfMonth(currentDate);
    const totalDays = [];

    for (let i = 0; i < firstDay; i++) {
      totalDays.push(<td key={`empty-${i}`} className="border p-2 md:p-3"></td>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const seats = seatAvailability[day];
      totalDays.push(
        <td key={day} className="border p-2 md:p-3 text-center relative">
          {seats && <div className="absolute inset-0 bg-blue-100 opacity-20"></div>}
          <div className="relative z-10">
            <div className="text-sm md:text-base">{day}</div>
            {seats && <div className="text-xs text-gray-600">{seats} seats</div>}
          </div>
        </td>
      );
    }

    const remainingDays = 42 - (firstDay + daysInMonth);
    for (let i = 1; i <= remainingDays; i++) {
      totalDays.push(
        <td key={`next-${i}`} className="border p-2 md:p-3 text-center text-gray-400 text-sm md:text-base">
          {i}
        </td>
      );
    }

    const weeks = [];
    for (let i = 0; i < totalDays.length; i += 7) {
      weeks.push(<tr key={`week-${i}`}>{totalDays.slice(i, i + 7)}</tr>);
    }

    return weeks;
  };

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1;
  };

  return (
    <>
      <section className="relative h-[300px] md:h-[500px] overflow-hidden">
      {/* Video Background */}
      <video 
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay 
        loop 
        muted 
        playsInline
      >
        <source 
          src="https://videos.pexels.com/video-files/2942803/2942803-uhd_2560_1440_24fps.mp4" 
          type="video/mp4" 
        />
      </video>

      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black/50"></div>

      {/* Content */}
      <div className="relative z-10 flex items-center justify-center h-full">
        <h2 className="text-3xl md:text-5xl font-bold text-white px-4">
        Pre Sea Course
        </h2>
      </div>
    </section>

      <div className="mb-8">
        {renderCourseList()}

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-xl p-4">
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h2 className="text-lg md:text-xl font-bold text-blue-900">
                    {selectedCourse?.courseName} - Available Seats
                  </h2>
                  <p className="text-sm md:text-base text-gray-500">{selectedCourse?.subTitle}</p>
                </div>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <X className="h-5 md:h-6 w-5 md:w-6" />
                </button>
              </div>

              {loading ? (
                <div className="text-center py-8">Loading...</div>
              ) : (
                <>
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-lg md:text-xl">
                      {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
                    </h3>
                    <div className="flex gap-2">
                      <button
                        onClick={() => {
                          setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
                          setSeatAvailability(generateDummyAvailability());
                        }}
                        className="p-2 hover:bg-gray-100 rounded-full"
                      >
                        <ChevronLeft className="h-4 md:h-5 w-4 md:w-5" />
                      </button>
                      <button
                        onClick={() => {
                          setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
                          setSeatAvailability(generateDummyAvailability());
                        }}
                        className="p-2 hover:bg-gray-100 rounded-full"
                      >
                        <ChevronRight className="h-4 md:h-5 w-4 md:w-5" />
                      </button>
                    </div>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr>
                          {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map(day => (
                            <th key={day} className="p-2 font-medium text-xs md:text-sm">
                              {day}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>{renderCalendar()}</tbody>
                    </table>
                  </div>

                  <div className="mt-6 space-y-2 text-xs md:text-sm text-gray-600">
                    <p>For further enquiries call on - <span className="text-blue-600"> 0 22 4516 4128, 0 22 4516 4141 </span></p>
                    <p>WhatsApp on - <span className="text-blue-600"> +91 7738350137, +91 7738350138</span></p>
                    <p>Email at - <span className="text-blue-600">info@rightships.com</span></p>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PreCourseCalendar