import React, { useState, useRef } from 'react';
import { Menu, X, ChevronDown, Briefcase } from 'lucide-react';
import { useLocation, Link } from 'react-router-dom';

import Logo from '../images/fullLogo.png';

const NavbarV2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);
  const location = useLocation();

  const courses = [
    { name: "Pre Sea Courses", path: "/pre-sea-courses" },
    { name: "Post Sea Courses", path: "/post-sea-courses" },
  ];

  const navLinks = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/about-us" },
    { name: "Services", path: "/our-services" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  const isActiveLink = (path) => location.pathname === path;

  const handleDropdownEnter = () => {
    if (window.innerWidth >= 768) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setIsDropdownOpen(true);
    }
  };

  const handleDropdownLeave = () => {
    if (window.innerWidth >= 768) {
      timeoutRef.current = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 200);
    }
  };

  return (
    <nav className="bg-white shadow-lg sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <Link to="/">
              <img src={Logo} alt="Logo" width={200} />
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center justify-center flex-1">
            <div className="flex space-x-4">
              {navLinks.map((link) => (
                <Link
                  key={link.path}
                  to={link.path}
                  className={`px-3 py-2 rounded-md text-sm lg:text-base hover:bg-blue-50 transition-colors ${
                    isActiveLink(link.path)
                      ? 'text-blue-600 font-semibold'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  {link.name}
                </Link>
              ))}
              
              {/* Courses Dropdown */}
              <div 
                ref={dropdownRef}
                className="relative"
                onMouseEnter={handleDropdownEnter}
                onMouseLeave={handleDropdownLeave}
              >
                <button
                  className={`px-3 py-2 rounded-md text-sm lg:text-base hover:bg-blue-50 transition-colors flex items-center gap-1 ${
                    courses.some(course => isActiveLink(course.path))
                      ? 'text-blue-600 font-semibold'
                      : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  Courses
                  <ChevronDown className="w-4 h-4 mt-0.5" />
                </button>
                
                {isDropdownOpen && (
                  <div 
                    className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
                    onMouseEnter={handleDropdownEnter}
                    onMouseLeave={handleDropdownLeave}
                  >
                    <div className="py-1">
                      {courses.map((course) => (
                        <Link
                          key={course.path}
                          to={course.path}
                          className={`block px-4 py-2 text-sm hover:bg-blue-50 ${
                            isActiveLink(course.path)
                              ? 'text-blue-600 font-semibold bg-blue-50'
                              : 'text-gray-700 hover:text-blue-600'
                          }`}
                        >
                          {course.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Jobs Button - Desktop */}
          <div className="hidden md:flex items-center">
            <Link
              to="/jobs"
              className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors flex items-center gap-2"
            >
              <Briefcase className="w-4 h-4" />
              <span>Jobs</span>
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-blue-600 hover:bg-blue-50"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-white">
          {navLinks.map((link) => (
            <Link
              key={link.path}
              to={link.path}
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                isActiveLink(link.path)
                  ? 'text-blue-600 bg-blue-50'
                  : 'text-gray-700 hover:bg-blue-50 hover:text-blue-600'
              }`}
              onClick={() => setIsOpen(false)}
            >
              {link.name}
            </Link>
          ))}
          
          {/* Mobile Courses Dropdown */}
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="w-full text-left px-3 py-2 rounded-md text-gray-700 hover:bg-blue-50 hover:text-blue-600 flex items-center justify-between"
            >
              <span className="font-medium">Courses</span>
              <ChevronDown className={`w-4 h-4 transform transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
            </button>
            {isDropdownOpen && (
              <div className="pl-4 space-y-1">
                {courses.map((course) => (
                  <Link
                    key={course.path}
                    to={course.path}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${
                      isActiveLink(course.path)
                        ? 'text-blue-600 bg-blue-50'
                        : 'text-gray-700 hover:bg-blue-50 hover:text-blue-600'
                    }`}
                    onClick={() => {
                      setIsOpen(false);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {course.name}
                  </Link>
                ))}
              </div>
            )}
          </div>

          {/* Jobs Link - Mobile */}
          <Link
            to="/jobs"
            className="block px-3 py-2 rounded-md text-base font-medium bg-blue-600 text-white hover:bg-blue-700 transition-colors mt-2 mx-1"
            onClick={() => setIsOpen(false)}
          >
            <div className="flex items-center gap-2">
              <Briefcase className="w-4 h-4" />
              <span>Jobs</span>
            </div>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavbarV2;