import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Header from "./Header";

// Import images for carousel
import Company1 from '../../images/companies/Company 1.jpg';
import Company2 from '../../images/companies/Company 2.jpg';
import Kenivaashipping from '../../images/companies/kenivaashipping.png';

import HeroSection2 from "../../component/common/heroic/heroic";
import ImageCarousel from "../../component/common/carousal/company";
import ImageCarousel2 from "../../component/common/carousal/companybg";
import ListCompany from "../../component/common/carousal/listCompany";

const HomePage = () => {

  const topCompanies = [
    {
      name: "Oceanone Ship Management",
      image: 'https://img1.wsimg.com/isteam/ip/5b79acce-44f2-46b4-9775-3b5feaddd159/New-Logo.png/:/rs=h:153,cg:true,m/qt=q:95',  // Replace with actual company logo
      link: "https://oceanoneshipmanagement.com/"
    },
    {
      name: "Kalyug Training",
      image: 'https://kalyugtraining.com/assets/images/logo/logo.png',  // Replace with actual company logo
      link: "https://kalyugtraining.com/Vessel_Flag_Change.php"
    },
    {
      name: "Garuda Travels",
      image: 'https://garudatravels.org/assets/images/logo.png',
      link: "https://garudatravels.org/"
    },
    {
      name: "AG Maritime",
      image: 'https://static.wixstatic.com/media/012c15_1ab8a39ae408418e940455f3d00dd1a6~mv2.jpg/v1/fill/w_97,h_80,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/012c15_1ab8a39ae408418e940455f3d00dd1a6~mv2.jpg',
      link: "https://www.ag-maritime.com/"
    },
    {
      name: "Ryan Ship Management",
      image: 'https://ryan-shipmanagement.com/wp-content/uploads/2023/11/rsm-logo-new1.png',
      link: "https://ryan-shipmanagement.com/"
    },
    {
      name: "BS Ship Management",
      image: Company2,
      link: "https://www.bs-shipmanagement.com/"
    },
    {
      name: "Scorpio Marine",
      image: Company1,
      link: "https://www.scorpiomarine.co.in/wp-content/uploads/2018/11/scorpio-logo.png"
    },
    {
      name: "MSC",
      image: Company1,
      link: "https://www.msc.com/en"
    },
    {
      name: "Synergy Marine Group",
      image: 'https://www.synergymarinegroup.com/wp-content/uploads/2023/11/synergy-logo.webp',
      link: "https://www.synergymarinegroup.com/"
    },
    {
      name: "PIL",
      image: 'https://www.pilship.com/wp-content/uploads/2024/05/Group_18367.png',
      link: "https://www.pilship.com/"
    },
    {
      name: "MOL MI",
      image: 'https://cms.molmi.info/assets/0b374760-37e5-4016-986b-b9b3a1798805',
      link: "https://www.molmi.info/"
    },
    {
      name: "Anglo Eastern",
      image: 'https://assets.angloeastern.com/assets/2023/01/ae-logo-2.svg',
      link: "https://www.angloeastern.com/"
    },
    {
      name: "Wallem",
      image: 'https://cdn.prod.website-files.com/62ffe1cb61fdf4d2762b1ca8/62ffe6ceeda62b2bcfbdb2da_logo.svg',
      link: "https://www.wallem.com/"
    },
    {
      name: "Ocean Star",
      image: 'https://oceanicstarshipping.com/img/logo/Logo%20ocenic.png',
      link: "https://oceanicstarshipping.com/"
    },
    // {
    //   name: "Maersk",
    //   image: Company1,
    //   link: "https://www.maersk.com/"
    // },
    {
      name: "Greatship",
      image: 'https://www.greatship.com/web_theme/images/about_logo.png',
      link: "https://www.greatship.com/"
    },
    {
      name: "Aayansh Shipping",
      image: 'https://aayanshshipping.com/wp-content/uploads/2024/05/WhatsApp-Image-2024-04-29-at-9.03.26-PM-e1714539478175.jpeg',
      link: "https://aayanshshipping.com/"
    },
    {
      name: "Olympia Ship",
      image: 'https://www.olympiaship.com/assets/img/white-logo.png',
      link: "https://www.olympiaship.com/"
    },
    {
      name: "Admiral Marine",
      image: 'https://cdn.prod.website-files.com/651126846a0defb65238201e/658403df44cd786f2f191994_Admiral%20Marine%20logo.png',
      link: "https://www.admiralmarine.com/"
    },
    {
      name: "TORM",
      image: 'https://s202.q4cdn.com/126069760/files/images/logo/torm_logo.svg',
      link: "https://www.torm.com/"
    }
  ];


  const standardCompanies = [
    {
      name: "Oceanone Ship Management",
      image: 'https://img1.wsimg.com/isteam/ip/5b79acce-44f2-46b4-9775-3b5feaddd159/New-Logo.png/:/rs=h:153,cg:true,m/qt=q:95',  // Replace with actual company logo
      link: "https://oceanoneshipmanagement.com/"
    },
    {
      name: "Shoolin Ship",
      image: 'https://shoolinship.com/wp-content/uploads/2023/10/cropped-WhatsApp-Image-2021-08-04-at-5.39.14-PM-SHOOLIN-100x112-1.png', // Replace with actual logo if available
      link: "https://shoolinship.com/"
    },
    {
      name: "Su-Nav",
      image: 'https://www.su-nav.co/wp-content/uploads/2019/10/SunavMarineHorizontal-biggerwp.png', // Replace with actual logo if available
      link: "https://www.su-nav.co/"
    },
    {
      name: "Navsug Marine Services",
      image: 'https://navsugmarineservices.com/wp-content/uploads/2024/11/cropped-LOGO-1.png', // Replace with actual logo if available
      link: "https://navsugmarineservices.com/"
    },
    {
      name: "Anglo Maritime Academy",
      image: 'https://anglomaritimeacademy.com/cdn/shop/files/PHOTO-2023-08-22-23-30-12.jpg?v=1692800200&width=100', // Replace with actual logo if available
      link: "https://anglomaritimeacademy.com/"
    },
    {
      name: "Dolphin Ship Management",
      image: 'https://www.dolphinshipmanagement.com/images/Dolphin_Logo.png', // Replace with actual logo if available
      link: "https://www.dolphinshipmanagement.com/"
    },
    {
      name: "Loadline Ships",
      image: 'https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=160,fit=crop,q=95/m7VVB07lxZF4krkY/Test-copy-m2WWoDlXJRHjWJZr.png', // Replace with actual logo if available
      link: "https://www.loadlineships.com/"
    },
    // {
    //   name: "CSS Ship Management",
    //   image: 'https://csship.com/assets/images/logo.png', // Replace with actual logo if available
    //   link: "https://csship.com/"
    // },
    // {
    //   name: "Shell Shipping and Maritime",
    //   image: 'https://www.shell.com/etc/designs/shell/logo.svg', // Replace with actual logo if available
    //   link: "https://www.shell.com/business-customers/trading-and-supply/shell-shipping-and-maritime/careers-in-shell-shipping.html"
    // },
    // {
    //   name: "Andromeda Shipping",
    //   image: 'https://www.andromeda-shipping.com/assets/images/logo.png', // Replace with actual logo if available
    //   link: "https://www.andromeda-shipping.com/"
    // },
    // {
    //   name: "Fleet Ship",
    //   image: 'https://www.fleetship.com/assets/images/logo.png', // Replace with actual logo if available
    //   link: "https://www.fleetship.com/"
    // },
    {
      name: "Darya Shipping",
      image: 'https://static.wixstatic.com/media/9f7eb9_8dbde4e22bb44e07b78f3fc9188576ad~mv2.png/v1/fill/w_231,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/Darya%20Logo.png', // Replace with actual logo if available
      link: "https://www.daryashipping.in/"
    },
    {
      name: "ABB Ship Management",
      image: 'https://abbshipmanagement.com/assets/images/ABB-WEB-LOGO.png', // Replace with actual logo if available
      link: "https://abbshipmanagement.com/"
    },
    {
      name: "BP Marine",
      image: 'https://www.bpmarine.in/images/logo.png', // Replace with actual logo if available
      link: "https://www.bpmarine.in/"
    },
    {
      name: "United Marine Academy",
      image: 'https://unitedmarineacademy.com/wp-content/uploads/logo-sm.png', // Replace with actual logo if available
      link: "https://unitedmarineacademy.com/"
    },
    // {
    //   name: "AIIMT",
    //   image: 'https://aiimtvns.com/mumbai/assets/images/logo.png', // Replace with actual logo if available
    //   link: "https://aiimtvns.com/mumbai/"
    // },
    {
      name: "Setrac",
      image: 'https://aiimtvns.com/mumbai/images/logo.png', // Replace with actual logo if available
      link: "http://setrac.in/"
    },
    {
      name: "AMI",
      image: 'https://anvaymaritime.com/assets/images/abb-logo-color.png', // Replace with actual logo if available
      link: "https://ami.appexonline.com/booking"
    },
    {
      name: "Coral Maritime Institute",
      image: 'https://coralmaritimeinstitute.com/wp-content/uploads/logo_02.png', // Replace with actual logo if available
      link: "https://coralmaritimeinstitute.com/"
    },
    {
      name: "CMC Maritime Chennai",
      image: 'https://www.cmcmaritimechennai.com/public/asset/cmc_header_logo.png', // Replace with actual logo if available
      link: "https://www.cmcmaritimechennai.com/"
    },
    {
      name: "Tolani Maritime Institute",
      image: 'https://tmi.tolani.edu/wp-content/uploads/2022/10/10/logo-with-text.png', // Replace with actual logo if available
      link: "https://tmi.tolani.edu/"
    },
    {
      name: "ABS Marine",
      image: 'http://absmarine.com/wp-content/uploads/2022/04/ABS-Registered-Logo-1-1.png', // Replace with actual logo if available
      link: "https://absmarine.com/"
    },
    {
      name: "ARC Ship",
      image: 'https://www.arcship.ae/wp-content/uploads/2024/01/arc_logo.svg', // Replace with actual logo if available
      link: "https://www.arcship.ae/"
    },
    {
      name: "Adsun Offshore",
      image: 'https://adsunoffshore.com/wp-content/uploads/2020/09/Adsun-Offshore-Logo.png', // Replace with actual logo if available
      link: "https://adsunoffshore.com/"
    },
    {
      name: "Elvis Ship Management",
      image: 'http://www.elvisshipmanagement.com/images/logo-dark.png', // Replace with actual logo if available
      link: "http://www.elvisshipmanagement.com/"
    },
    {
      name: "Goodwood Ship Management",
      image: 'https://www.goodwoodship.com.sg/images/logo.png', // Replace with actual logo if available
      link: "https://www.goodwoodship.com.sg/"
    }
  ];

  const listCompany = [
    {
      name: "Shoolin Ship",
      image: Kenivaashipping, // Replace with actual logo if available
      link: "https://www.kenivaashipping.com/"
    },
    {
      name: "Shoolin Ship",
      image: 'http://mmti.co.in/img/MMTILogo.jpeg', // Replace with actual logo if available
      link: "http://mmti.co.in/courses.aspx"
    },
    {
      name: "Shoolin Ship",
      image: 'https://www.tangarshipping.com/img/TANGAR.png', // Replace with actual logo if available
      link: "https://www.tangarshipping.com/"
    },
    {
      name: "Shoolin Ship",
      image: 'https://shoolinship.com/wp-content/uploads/2023/10/cropped-WhatsApp-Image-2021-08-04-at-5.39.14-PM-SHOOLIN-100x112-1.png', // Replace with actual logo if available
      link: "http://www.dynacomtm.com/"
    },
    {
      name: "Shoolin Ship",
      image: 'https://www.holyangelmarineservices.com/wp-content/uploads/elementor/thumbs/New-Logo-orsajim69ily8qonc01ah0rqvw89kdl0qdbdxniydw.png', // Replace with actual logo if available
      link: "https://www.holyangelmarineservices.com/"
    },
    {
      name: "Shoolin Ship",
      image: 'https://www.damicoishima.com/wp-content/themes/damico-ishima/images/logo2.jpg', // Replace with actual logo if available
      link: "https://www.damicoishima.com/"
    },
    {
      name: "Shoolin Ship",
      image: 'https://bellfranc.com/images/logo.png', // Replace with actual logo if available
      link: "https://bellfranc.com/"
    },
  ];
  

  return (
    <div className="bg-gray-50">
     
      <HeroSection2/>
      <div className="container mx-auto py-6">
        <ImageCarousel
            images={topCompanies}
            title="Top Companies"
            itemsPerSlide={6}
            autoPlayInterval={3000}
            onImageClick={(index) => console.log(`Clicked company ${index + 1}`)}
          />
      </div>

       <section className="bg-[#EFF7FF]">
        <div className="container mx-auto py-6">
          <ImageCarousel2
            images={standardCompanies}
            title="Standard Companies"
            itemsPerSlide={12}
            autoPlayInterval={3000}
            onImageClick={(index) => console.log(`Clicked company ${index + 1}`)}
          />
          </div>
        </section>
       
        <div className="container mx-auto py-6">
        <ListCompany 
         images={listCompany}
         title="List Companies"
         itemsPerSlide={12}
         autoPlayInterval={3000}
         onImageClick={(index) => console.log(`Clicked company ${index + 1}`)}
        />
        </div>
      
    </div>
  );
};

export default HomePage;